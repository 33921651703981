@use '@angular/material' as mat;
@include mat.core();

html, body {
  height: 100%; width: 100%;
  @media screen and (min-resolution: 1.25x)  {
    zoom: 90%;
  }
  @media screen and (min-resolution: 1.5x)  {
    zoom: 80%;
  }

}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; position: absolute;}
app-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.xng-breadcrumb-trail {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.state- {
  &compliant {
    color: var(--color_compliant) !important;
  }
  &ignored {
    color: var(--color_compliant_none) !important;
  }
  &not_compliant {
    color: var(--color_not_compliant) !important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer !important;
  }

  &-add {
    cursor: cell !important;
  }

}

// Charts
.chart-title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  font-weight: 800;
  height: 2rem;
  font-size: large;
}

//.cdk-global-overlay-wrapper {
//  justify-content: flex-end !important;
//  align-items: stretch !important;
//}
